<template>
  <div id="content">
    <div style="padding-top: 20px">
      <el-row :gutter="24" style="display: flex;flex-flow: wrap">
        <el-col :span="6" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">代理编号：</span>
          <el-input placeholder="请输入代理商编号或代理商名称" v-model="searchData.agentId" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="5" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">手机号：</span>
          <el-input placeholder="请输入手机号" v-model="searchData.phone" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="6" style="display: flex;text-align: center;align-items: center">
          <span class="searchText">应用号：</span>
          <el-input placeholder="请输入应用号或应用名称" v-model="searchData.mchId" size="mini"
                    style="width: 230px" clearable/>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="initData">查询</el-button>
          <el-button type="default" icon="el-icon-refresh-right" size="mini" @click="queryRest">重置</el-button>
        </el-col>
      </el-row>
      <el-divider/>
    </div>
    <div>
      <el-button type="primary" icon="el-icon-plus" size="mini" style="margin-left: 40px;margin-bottom: 20px;"
                 @click="addDrawerFlag=true">新增商户
      </el-button>
    </div>
    <HssTable :table-header="tableHeader" :table-data="list" :loading="loading">
      <template #agentName="{row}">
        <div style="display: flex;flex-direction: column;">
          {{row.agentName}}
          <span style="color: #135AFA;font-size: 12px;font-weight: bold">
              {{ row.aid }}
              <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;" @click="copyText(row.aid)"></i>
            </span>
        </div>
      </template>
      <template #id="{row}">
        <span @click="$router.push(`/mch/mch-detail?mchId=${row.mid}`)" style="color: #135AFA;cursor: pointer;">{{ row.mid }}</span>
        <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;" @click="copyText(row.mid)"></i>
      </template>
      <template #mchName="{row}">
        <span @click="$router.push(`/mch/mch-detail?mchId=${row.mid}`)" style="color: #135AFA;font-weight: 800;cursor: pointer;">{{ row.mname }}</span>
      </template>
      <template #mphone="{row}">
        <span>{{ row.mphone }}</span>
        <i class="el-icon-document-copy" style="cursor: pointer;margin-left: 4px;" @click="copyText(row.mphone)"></i>
      </template>
      <template #status="{row}">
        <el-badge is-dot :type="row.status == 0 ? 'success' : 'error'" style="margin-top: -3px;margin-right: 5px;"></el-badge>
        <span>{{ row.status === 0 ? '正常' : '禁用' }}</span>
      </template>
      <template #createTime="{row}">
        <span>{{ row.createTime.replace("T", " ") }}</span>
      </template>
      <template #operation="{row}">
        <el-button style="font-weight: bold" icon="el-icon-full-screen" type="text" @click="getImg(row, 'gzh')">收款码</el-button>
        <el-button style="font-weight: bold" icon="el-icon-full-screen" type="text" @click="getImg(row, 'mini')" 
                   v-if="$store.getters.userInfo.parentId == 'sys' || $store.getters.userInfo.id == 'A1775344862'">小程序收款码</el-button>
        <!--<el-button style="font-weight: bold" icon="el-icon-full-screen" type="text" @click="yqm(row)">邀请码</el-button>-->
        <el-button style="font-weight: bold" icon="el-icon-edit" type="text" @click="preUpdateMch(row)">修改</el-button>
<!--        <el-dropdown @command="fzpz" style="margin-left: 20px;" trigger="click" placement="bottom-end" v-if="$store.getters.userInfo.userName == 'admin'">-->
<!--          <el-button type="text">分账配置<i class="el-icon-caret-bottom"></i></el-button>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <el-dropdown-item :command="`3-${row.mid}`" style="color: #5157f7">优胜分账</el-dropdown-item>-->
<!--            <el-dropdown-item :command="`5-${row.mid}`" style="color: #5157f7">文慧付分账</el-dropdown-item>-->
<!--            <el-dropdown-item :command="`6-${row.mid}`" style="color: #5157f7">汇付分账</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
      </template>
    </HssTable>
    <pagination
        :total="total"
        :pageNum.sync="pageNum"
        :limit.sync="pageSize"
        @pagination="initData()"
    />
    <el-dialog
        :visible.sync="dialogVisible2"
        :modal="false">
      <div style="display:flex;flex-direction: column;align-items: center;">
        <!--<canvas class="qrcode" ref="qrCodeDiv" id="qrcode" width="550" height="600"></canvas>-->
        <canvas class="qrcode" ref="qrCodeDiv" id="qrcode" width="517" height="735"></canvas>
        <el-button style="width: 130px;" type="primary" @click="down">下载收款码</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisible"
        :modal="false"
        width="20vw">
      <div style="display:flex;flex-direction: column;">
        <div class="qrcode2" ref="qrCodeDiv2"></div>
        <span style="text-align: center;margin-top: 10px;font-size: 12px;color: red;">{{ currentMch.mname }}进件申请表</span>
      </div>
    </el-dialog>
    <!--  新增商户弹窗  -->
    <el-drawer
        title="新增商户"
        size="40%"
        :wrapperClosable="false"
        :modal="false"
        :close-on-press-escape="false"
        :visible.sync="addDrawerFlag"
        direction="rtl">
      <div class="drawer-content">
        <el-form :model="addMchForm" :rules="addMchFormRules" ref="addMchForm" label-width="100px"
                 class="demo-ruleForm2">
          <el-form-item label="商户名称" prop="mchName">
            <el-input clearable placeholder="商户名称" size="mini" v-model="addMchForm.mchName"></el-input>
          </el-form-item>
          <el-form-item label="登录名" prop="userName">
            <el-input clearable placeholder="登录名" size="mini" v-model="addMchForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="商户简称" prop="mchFor">
            <el-input clearable placeholder="商户简称" size="mini" v-model="addMchForm.mchFor"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="name">
            <el-input clearable placeholder="联系人姓名" size="mini" v-model="addMchForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="email">
            <el-input clearable placeholder="联系人邮箱" size="mini" v-model="addMchForm.email"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="phone">
            <el-input clearable placeholder="联系人手机号" size="mini" v-model="addMchForm.phone" @keydown.native="addMchForm.phone = addMchForm.phone.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>
          <el-form-item prop="mchLevel">
            <template slot="label">
              <span>商户级别</span>
              <el-popover
                  placement="top-start"
                  title="商户级别"
                  width="450"
                  trigger="hover">
                <el-divider></el-divider>
                <div style="font-size: 14px">
                  <span>M0商户：简单模式  (页面简洁，仅基础收款功能)</span>
                </div>
                <div style="font-size: 14px;margin-top: 20px;">
                  <span>M1商户：高级模式  (支持api调用，支持配置应用分账、转账功能)</span>
                </div>
                <i style="margin-left: 5px;" class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <el-radio-group v-model="addMchForm.mchLevel">
              <el-radio :label="0">M0</el-radio>
              <el-radio :label="1">M1</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="addMchForm.status">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="padding-bottom: 40px;" label="备注" prop="remark">
            <el-input style="width: 600px" clearable placeholder="备注" v-model="addMchForm.remark"></el-input>
          </el-form-item>
          <el-divider content-position="left">
            <el-tag size="mini" effect="dark" type="danger">账号安全</el-tag>
          </el-divider>
          <el-form-item label="是否发送开通提醒" prop="status">
            <el-radio-group v-model="addMchForm.messageType">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="密码设置" prop="status">
            <el-radio-group v-model="addMchForm.passwordSettingsFlag">
              <el-radio :label="0">默认密码</el-radio>
              <el-radio :label="1">自定义密码</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="addMchForm.passwordSettingsFlag == 1" label="登录密码" prop="status">
            <el-input clearable placeholder="请输入登录密码" size="mini" v-model="addMchForm.password"></el-input>
            <div>
              <el-button icon="el-icon-postcard" size="small" type="primary"
                         @click="addMchForm.password = randomPassword(6)">随机生成密码
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center;margin-top: 8%;padding-bottom: 10px;" class="drawer-footer">
        <el-button style="width: 100px" icon="el-icon-check" size="small" type="primary" @click="addMch('addMchForm')">
          保存
        </el-button>
        <el-button style="width: 100px" icon="el-icon-close" size="small" @click="closeDrawer">取消</el-button>
      </div>
    </el-drawer>
    <!--  修改商户弹窗  -->
    <el-drawer
        title="修改商户"
        size="40%"
        :modal="false"
        :wrapperClosable="false"
        :close-on-press-escape="false"
        :visible.sync="updateDrawerFlag"
        direction="rtl">
      <div class="drawer-content">
        <el-form :model="updateMch" :rules="updateMchFormRules" ref="updateMch" label-width="100px"
                 class="demo-ruleForm2">
          <el-form-item label="商户名称" prop="mname">
            <el-input clearable placeholder="商户名称" size="mini" v-model="updateMch.mname"></el-input>
          </el-form-item>
          <el-form-item label="登录名" prop="userName">
            <el-input disabled clearable placeholder="登录名" :disabled="true" size="mini"
                      v-model="updateMch.userName"></el-input>
          </el-form-item>
          <el-form-item label="商户简称" prop="mnameFor">
            <el-input clearable placeholder="商户简称" size="mini" v-model="updateMch.mnameFor"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="name">
            <el-input clearable placeholder="联系人姓名" size="mini" v-model="updateMch.name"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="memail">
            <el-input clearable placeholder="联系人邮箱" size="mini" v-model="updateMch.memail"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="mphone">
            <el-tooltip class="item" effect="dark" content="同步更改登录手机号" placement="bottom-start">
              <el-input clearable placeholder="联系人手机号" size="mini" v-model="updateMch.mphone"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item prop="mchLevel">
            <template slot="label">
              <span>商户级别</span>
              <el-popover
                  placement="top-start"
                  title="商户级别"
                  width="450"
                  trigger="hover">
                <el-divider></el-divider>
                <div style="font-size: 14px">
                  <span>M0商户：简单模式  (页面简洁，仅基础收款功能)</span>
                </div>
                <div style="font-size: 14px;margin-top: 20px;">
                  <span>M1商户：高级模式  (支持api调用，支持配置应用分账、转账功能)</span>
                </div>
                <i style="margin-left: 5px;" class="el-icon-question" slot="reference"></i>
              </el-popover>
            </template>
            <el-radio-group v-model="addMchForm.mchLevel">
              <el-radio :label="0">M0</el-radio>
              <el-radio :label="1">M1</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="updateMch.status">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" prop="" v-if="this.$store.getters.userInfo.parentId == 'sys'">
            <el-dropdown @command="fzpz" style="margin-left: 20px;" trigger="click" placement="bottom-end"
                         v-if="this.$store.getters.userInfo.parentId == 'sys'">
              <el-button type="text">分账配置<i class="el-icon-caret-bottom"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="`1-${updateMch.mid}`" style="color: #5157f7">银盛</el-dropdown-item>
                <el-dropdown-item :command="`9-${updateMch.mid}`" style="color: #5157f7">直付通</el-dropdown-item>
                <el-dropdown-item :command="`12-${updateMch.mid}`" style="color: #5157f7">盛付通</el-dropdown-item>
                <el-dropdown-item :command="`2-${updateMch.mid}`" style="color: #5157f7">汇付</el-dropdown-item>
                <el-dropdown-item :command="`3-${updateMch.mid}`" style="color: #5157f7">易生</el-dropdown-item>
                <el-dropdown-item :command="`4-${updateMch.mid}`" style="color: #5157f7">新生</el-dropdown-item>
                <el-dropdown-item :command="`5-${updateMch.mid}`" style="color: #5157f7">汇聚</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
          <el-form-item v-if="this.$store.getters.userInfo.parentId == 'sys'"></el-form-item>
          <el-form-item style="padding-bottom: 40px;" label="备注" prop="remark">
            <el-input style="width: 600px" clearable placeholder="备注" v-model="addMchForm.mremark"></el-input>
          </el-form-item>
          <el-divider content-position="left">
            <el-tag size="mini" effect="dark" type="danger">账号安全</el-tag>
          </el-divider>
          
        </el-form>
      </div>
      <div style="text-align: center;margin-top: 8%;padding-bottom: 10px;" class="drawer-footer">
        <el-button style="width: 100px" icon="el-icon-check" size="small" type="primary" @click="addMch('updateMch')">
          保存
        </el-button>
        <el-button style="width: 100px" icon="el-icon-close" size="small" @click="closeDrawer">取消</el-button>
      </div>
    </el-drawer>
    <el-dialog
      title="分账设置"
      :modal="false"
      :visible.sync="fzFlag"
      width="30%" v-if="this.$store.getters.userInfo.parentId == 'sys'">
      <div style="display:flex;flex-direction: column;">
        <div style="display:flex;align-items: center">
          <span style="width: 140px">比例设置:</span>
          <el-input v-model="fzRate" maxlength="4" placeholder="比例设置（最低0.1%最高1）">
            <i slot="suffix" class="el-input__icon">%</i>
          </el-input>
        </div>
        <div style="display:flex;margin-top: 20px;align-items: center">
          <span style="width: 140px">接收方商户号：</span>
          <el-input v-model="fzMchId" placeholder="请输入分账接收方商户号"/>
        </div>
        <div style="display:flex;margin-top: 20px;justify-content: center">
          <el-button type="primary" @click="addFz">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HssTable from "@/components/HssTable.vue";
import {list,addMch,separateQuery, separateAdd} from "@/api/mch/mch";
import Pagination from "@/components/Pagination/index.vue";
import QRCode from 'qrcodejs2'

let QRCode2 = require('qrcode');
export default {
  name: "Index",
  components: {Pagination, HssTable},
  data() {
    let userNameRules = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入登录名'));
      }
      setTimeout(() => {
        const regExp = /^[a-zA-Z]\w{5,17}$/;
        if (!regExp.test(value)) {
          callback(new Error('请输入字母开头，长度为6-18位的登录名'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      // 分账设置
      fzRate: '',
      fzMchId: '',
      fzInfo: {},
      fzFlag: false,
      
      dialogVisible: false,
      dialogVisible2: false,
      loading: false,
      addDrawerFlag: false,
      updateDrawerFlag: false,
      list: [],
      currentMch: {},
      updateMch: {},
      total: 0,
      pageSize: 10,
      pageNum: 1,
      searchData: {
        agentId: '',
        phone: '',
        mchId: ''
      },
      tableHeader: [
        {
          label: '所属代理商',
          prop: 'agentName',
          scopeStatus: true,
        },
        {
          label: '应用号',
          prop: 'id',
          scopeStatus: true,
        },
        {
          label: '应用名称',
          prop: 'mchName',
          scopeStatus: true,
        },
        {
          label: '手机号',
          prop: 'mphone',
          scopeStatus: true,
        },
        {
          label: '状态',
          prop: 'status',
          scopeStatus: true,
        },
        {
          label: '创建时间',
          prop: 'createTime',
          scopeStatus: true,
        },
        {
          label: '操作',
          prop: 'operation',
          scopeStatus: true,
          isfixed: 'right',
          minWidth: 200
        },
      ],
      addMchForm: {
        mchName: '',
        userName: '',
        mchFor: '',
        name: '',
        email: '',
        phone: '',
        remark: '',
        password: '',
        status: 0,
        mchLevel: 0,
        messageType: 0,
        passwordSettingsFlag: 0

      },
      updateMchFormRules: {
        mName: [
          {required: true, message: '请输入商户名称', trigger: 'blur'}
        ],
        mchNameFor: [
          {required: true, message: '请输入商户简称', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'}
        ],
        mPhone: [
          {required: true, message: '请输入联系人手机号', trigger: 'blur'}
        ],
      },
      addMchFormRules: {
        mchName: [
          {required: true, message: '请输入商户名称', trigger: 'blur'}
        ],
        userName: [
          {required: true, validator: userNameRules, trigger: 'blur'}
        ],
        mchFor: [
          {required: true, message: '请输入商户简称', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入联系人手机号', trigger: 'blur'}
        ],
      },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    addFz() {
      if (this.fzRate > 5){
        this.$message.error("比例设置错误")
        return ;
      }
      if(!this.fzRate || !this.fzMchId){
        this.$message.error("参数设置错误")
        return ;
      }
      let param = {
        tenantId: this.currentMch.tenantId,
        mchId: this.currentMch.mchId,
        rate: this.fzRate,
        userCode: this.fzMchId,
        id: this.fzInfo ? this.fzInfo.id : null
      }
      separateAdd(param).then(res => {
        if (this.fzInfo){
          this.$message.success("修改配置成功！")
        }else{
          this.$message.success("配置成功！")
        }
        this.fzFlag = false
      })
      
    },
    fzpz(cmd) {
      this.fzRate = ''
      this.fzMchId = ''
      this.fzInfo = {}
      let split = cmd.split("-");
      this.currentMch = {tenantId: split[0], mchId: split[1]};
      let param = {
        tenantId: this.currentMch.tenantId,
        mchId: this.currentMch.mchId
      }
      separateQuery(param).then(res => {
        if (res.data) {
          this.fzInfo = res.data
          this.fzRate = res.data.rate;
          this.fzMchId = res.data.upUserCode
        }
      })
      this.fzFlag = true;
    },
    preUpdateMch(mch) {
      this.updateMch = mch;
      this.updateDrawerFlag = true;
    },
    addMch(formName) {
      if (formName == 'updateMch') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let param = {
              mId: this.updateMch.mid,
              mchName: this.updateMch.mname,
              phone: this.updateMch.mphone,
              email: this.updateMch.memail || null,
              mchFor: this.updateMch.mnameFor,
              name: this.updateMch.name,
              loginName: this.updateMch.userName,
              remark: this.addMchForm.mremark || null,
              status: this.updateMch.status,
              password: this.updateMch.password || '123456',
              aId: this.$store.getters.userInfo.id
            }
            addMch(param).then(res => {
              if (res.success) {
                this.$message.success("修改成功")
                this.updateDrawerFlag = false;
                this.initData();
              } else {
                this.$message.error("修改失败")
              }
            })
          }
        })
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let param = {
              mchName: this.addMchForm.mchName,
              phone: this.addMchForm.phone,
              email: this.addMchForm.email || null,
              mchFor: this.addMchForm.mchFor,
              name: this.addMchForm.name,
              loginName: this.addMchForm.userName,
              remark: this.addMchForm.remark || null,
              status: this.addMchForm.status,
              password: this.addMchForm.password || '123456',
              aId: this.$store.getters.userInfo.id
            }
            addMch(param).then(res => {
              if (res.success) {
                this.$message.success("新增成功")
                this.addDrawerFlag = false;
                this.initData();
              } else {
                this.$message.success("新增失败")
              }
            })
          }
        });
      }
    },
    closeDrawer() {
      this.addDrawerFlag = false;
      this.updateDrawerFlag = false;
      this.addMchForm = {
        mchName: '',
        userName: '',
        mchFor: '',
        name: '',
        email: '',
        phone: '',
        remark: '',
        password: '',
        status: 0,
        mchLevel: 0,
        messageType: 0,
        passwordSettingsFlag: 0

      }
    },
    randomPassword(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    down(){
      let canvas = document.getElementById('qrcode')
      const a = document.createElement('a') // 创建一个 a 标签
      a.href = canvas.toDataURL('image/jpeg', 1.0)
      a.download = '收款码.jpg'
      a.click()
    },
    async generateQR(url) {
      return QRCode2.toDataURL(url)
    },
    getImg(row, type) {
      let _this = this;
      _this.dialogVisible2 = true;
      // _this.generateQR(`https://hss.fwypay.com/v1/v1/public/getWxPayApp/${row.mid}`).then(res => {
      //   _this.generateQR(`https://hss.fwypay.com/pay?sk=${row.mid}`).then(res => {
      // let url = `http://127.0.0.1:9001/pay?sk=${row.mid}`;
      let url = '';
      if(type === 'mini'){
        url = `http://hss.fwypay.com/wxJump?sk=${row.mid}`;
      }else{
        url = `http://hss.fwypay.com/pay?sk=${row.mid}`;
      }
      console.log(url);
        _this.generateQR(url).then(res => {
        let canvas = document.getElementById('qrcode')
        let ctx = canvas.getContext('2d')
        let img1 = new Image()
        let img2 = new Image()
        img1.crossOrigin = 'anonymous'
        img2.crossOrigin = 'anonymous'

        img1.src = require("@/assets/img/skmm2.png")
        img2.src = res // 生成的二维码base64
        img1.onload = function () {
          ctx.drawImage(img1, 0, 0, 517, 735) // 背景图载入画板
          ctx.drawImage(img2, 130, 300, 258, 258)
          ctx.font = 'bold 18px Consolas'
          ctx.textAlign="center"
          ctx.textBaseline="middle"
          ctx.beginPath();
          ctx.closePath();
          const fix = ctx.measureText(row.mname).actualBoundingBoxAscent + ctx.measureText(row.mname).actualBoundingBoxDescent;
          // ctx.fillText(row.mname, canvas.width / 2 +25, 220);
          ctx.fillText(row.mname, canvas.width / 2, 255);
        }
      })
    },
    yqm(obj) {
      this.currentMch = obj;
      this.dialogVisible = true;
      this.$nextTick(() => {
        let div = document.createElement('div');// 创建一个div,用来生成二维码
        // 生成二维码
        let qrcode = new QRCode(div, {
          text: 'http://hss.fwypay.com/h5-piece?agentId=' + this.$store.getters.userInfo.id + "&mchId=" + obj.mid, // 你的扫码内容,填网址
          width: 150, // 二维码宽度
          height: 150, // 二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //从上至下生成码密度越来越高 L - M - Q - H
          // 容错率越高,越复杂
        })
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = require("@/assets/img/hss.png") // 填入你本地log图片
        // 生成log图
        logo.onload = () => {
          let container = this.$refs['qrCodeDiv2'];
          // 获取页面上的div,可以使用document.querySelector()等等方法,不类推了
          if (container.innerHTML != "") {
            // 获取页面div , 有则清空已存在的
            container.innerHTML = ""
          }
          let qrImg = qrcode._el.getElementsByTagName('img')[0]; // 获取二维码
          let canvas = qrcode._el.getElementsByTagName('canvas')[0]; // 获取canvas

          let ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 150 * 0.5 - 22, 150 * 0.5 - 22, 44, 44); // 写入log
          qrImg.src = canvas.toDataURL();
          container.appendChild(qrcode._el);
        }
      })
    },
    copyText(content) {
      this.$copyText(content).then((e) => {
        this.$message.success('复制成功');
      }, function (e) {
        this.$message.error('复制失败')
      })
    },
    queryRest() {
      this.searchData = {
        agentId: '',
        phone: '',
        mchId: ''
      }
      this.initData()
    },
    initData() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        mId: this.searchData.mchId || null,
        aId: this.searchData.agentId ? this.searchData.agentId : this.$store.getters.userInfo.userName == 'admin' ? null : this.$store.getters.userInfo.id,
        phone: this.searchData.phone || null
      }
      list(data).then(res => {
        this.list = res.data.list
        this.total = res.data.total
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped lang="less">
#content {
  //width: 100%;
  background: #ffffff;
  //min-height: 90vh;
  margin: 16px 0 0 0;
}

.searchText {
  font-size: 12px;
  margin-left: 20px;
  font-weight: bold;
  width: 120px;
}
/deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
}
.demo-ruleForm2 {
  display: flex;
  flex-flow: wrap;
}

.demo-ruleForm2 .el-form-item {
  display: flex;
  margin: 20px;
  width: 280px;
  height: 45px;
  flex-direction: column;
  text-align-last: start;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__content {
  margin-left: 0px !important;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__label {
  height: 30px !important;
  width: 128px !important;
}

.demo-ruleForm {
  display: flex;
}

/deep/ .demo-ruleForm .el-form-item__content {
  margin-left: 0px !important;
}
/deep/.el-divider--horizontal{
  height: 0;
  border: 1px solid #f4f6f9;
  margin-bottom: 10px;
}
</style>
